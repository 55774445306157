.MuiSvgIcon-root.icon{ 
    width: 40px;
    height:40px;
    color:#546e7a;
}
.root {
    flex-Grow: 1;
    padding:20px;
}
.paper {
    color:#546e7a !important;
    min-height:90px;
    padding:15px;
    font-size:11px;
    font-weight:500;
    letter-spacing: 0.33px;
    text-transform: uppercase;
}
.icon{
    float:right;
    margin-top:4px;
}
.countData{
    padding-top: 10px;
    font-size: 11px;
}
.apexcharts-menu-icon{
    margin-right: 8px !important;
}

