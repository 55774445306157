body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Link{
  text-decoration: none !important;
  color:#59388f;
}
.Link a{
  text-decoration: none !important;
}
.actionIcon{
  color:rgba(0, 0, 0, 0.54);
}
.MuiTableCell-head{
    font-weight:550 !important;
}
.chipEffect{
  color:white !important;
  border-radius: 4px !important;
  height:25px !important;
}
.messageText {
  font-size: 14px !important;
  color: white !important;
}
.messageCont {
  width: 100%;
}
.blockLoader {
  text-align:center;
  margin-top:40px;
}
.inlineLoader {
  width: 200px;
  margin-left: 40px;
  display: inline-block;
  vertical-align: middle;
}
.searchBox{
  border-radius: 25px;
  border: 1px solid lightgray;
  margin-bottom: 20px;
  display: inline-flex;
  height: 45px;
  align-items: center;
}
.adminSearchBox{
  border-radius: 25px;
  border: 1px solid lightgray;
  display: inline-flex;
  height: 45px;
  align-items: center;
}
.addButton {
  justify-items: right;
  display: grid;
}
.model .MuiDialog-paperWidthSm{
  min-width: 500px !important;
  width: 500px !important;
}
.loading {
  position: fixed;
  z-index: 999;
  overflow: visible;
  margin: auto;
  top: 50% !important;
  left: 50% !important;
}
/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  background-color: #75757573;
}
.paymentloading {
  position: fixed;
  z-index: 999;
  overflow: visible;
  margin: auto;
  top: 50% !important;
  left: 55% !important;
}
.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-7.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0{
  z-index: auto !important;
}
.storageTitle{
  font-size:18px;
  text-align-last:center;
  color:#3f51b5e8;
}
.storageValue{
  font-weight:500;
  font-size:18px;
  color:#3f51b5e8;
}
.storageValueBlock{
    font-size:14px;
    text-align-last:center;
}
.totalStorageValue{
    font-weight:500;
    font-size:18px;
}
.circulerProgress{
  text-align-last:center;
  padding:20px 0px 20px 0px;
}
/*filter css*/
.searchIcon{
  font-size:24px !important;
  margin-top:5px !important;
  width:50px !important;
}
.moreFilterLabel{
  padding-right:5px;
  cursor:pointer;
  color: #777777;
  font-size: 12px;
  text-decoration: underline;
}
.moreFilterTitle{
  float:left;
  margin-top:20px;
  margin-left:30px;
  font-size:24px;
}
.cancelIcon{
  float:right;
  margin-top:24px;
  margin-right:20px;
  cursor:pointer;
}
/*customer page css*/
.dialogCloseIcon{
  float:right;
  margin-bottom:-25px !important;
  margin-top:-8px !important;
}
/*profile page css*/
.paymentMessage{
  font-size:14px;
  margin-left:5px;
  margin-top:20px;
  color:red;
}
.safeBlock{
  background-color:#34ebb4;
  color:white;
  padding:15px;
  border-radius:5px;
  font-size:14px;
  padding-top:10px;
}
.notSafeBlock{
  font-size:13px;
  margin-top:4px;
  background:#f44336;
  color:white;
  padding:15px;
  border-radius:5px;
}
.usageTitle{
  font-size:17px !important;
  font-weight:500 !important;
  margin-bottom:10px !important;
}
.usageBlock{
  font-weight:500 !important;
  font-size:15px !important;
}
.Paper{
  padding:20px;
}
.paymentData .MuiTypography-colorInherit{
  display:none!important;
}
.paymentDataLabel{
  font-size: 11px;
  border-radius: 3px 3px 3px 3px;
  padding: 5px;
  width: 100px;
  text-align-last: center;
}
.activePayment{
  pointer-events: none;
  background: lightgray;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.disablePayment{
  cursor: pointer;
  background:#d3d3d340;
}
.TableWordBreak{
  word-break: break-all;
}
.Inputlabel{
  font-size:15px;
  margin-bottom:7px;
}
.selectUI{
  font-size: 16px;
  padding:12px;
  border-radius:4px;
  width:600px;
  max-width: 600px;
  background-color: #fafafa;
}
.validationError {
  color: red;
  font-size: 12px;
}
.backArrowIconReport{
  font-size: 25px;
  color:black;
  margin-right:10px;
  margin-top:4px;
}
#graph-type-select{padding: 8px 10px;padding-right: 32px;font-size: 12px;}
